



















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class DetailRow extends Vue {
    @Prop({required: true}) public rowData!: Object;
    @Prop({required: true}) public rowIndex!: Number;

    public onClick (event: Event): void {
    }

    public mounted() {
    }
}
