
import { Component, Vue, Prop } from 'vue-property-decorator';
import table from '@/utils/table';

@Component({})
export default class BaseField extends Vue {
  @Prop({ required: true }) public props!: any;
  protected canBeEdited: Boolean =
    !(<any>this.$parent.$parent).notEditable || false;

  get rawData(): any {
    return table.findByPath(this.props.rowData, this.props.rowField.property);
  }

  set rawData(newValue: any) {
    table.setByPath(this.props.rowData, this.props.rowField.property, newValue);
    (<any>this.$parent.$parent).modify(this.props.rowData);
  }

  get computedCanBeEdited(): Boolean {
    return !this.props.rowField.isNotEditable && this.canBeEdited;
  }

  protected getByProperty(property: string): any {
    return table.findByPath(this.props.rowData, property);
  }
}
